<template>
  <component :is="as" :class="[$style.pill, $style[variant]]">
    <slot />
    <template v-if="isClearable">
      <BaseButton variant="icon" @click="$emit('clear')">
        <BaseIcon icon="times" :class="[$style.clearIcon, $style[variant]]" />
      </BaseButton>
    </template>
  </component>
</template>

<script>
const VARIANTS = ["primary", "primary-outline"];
import BaseButton from "./BaseButton";
import BaseIcon from "./BaseIcon";
export default {
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    as: {
      type: String,
      default: "div",
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
      validation: (value) => VARIANTS.includes(value),
    },
  },
};
</script>

<style lang="scss" module>
.pill {
  padding: 0.375rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;

  &.primary {
    color: white;
    background: $color-primary;
  }

  &.primary-outline {
    color: $color-primary;
    border: 1px solid #{$color-primary};
    background: white;
  }
}

.clearIcon {
  padding: 0;
  &.primary {
    color: white;
  }

  &.primary-outline {
    color: $color-primary;
  }
}
</style>