<template>
  <div>
    <template v-if="!isUserInitialised">
      <p>Loading...</p>
    </template>
    <template v-else-if="permissionError === 'FORBIDDEN'">
      <div>You have no access to this application.</div></template
    >
    <component v-else :is="isMainApp ? 'Rightsholders' : 'router-view'" />
  </div>
</template>

<script>
import { APPS, APP_NAME } from '@/constants/apps';
import CountriesTest from './CountriesTest.vue';
import { firebase } from '@/config/firebase.config';
import { fetchUser } from '@/api/userApi';
import { setUser, userComputed } from '@/services/userService';

const Rightsholders = () => import('@/views/Rightsholders');

export default {
  components: {
    Rightsholders,
    CountriesTest,
  },
  data() {
    return {
      permissionError: null,
    };
  },
  created() {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const {
            uid,
            phoneNumber,
            photoURL,
            emailVerified,
            displayName,
            email,
            isAnonymous,
          } = user;

          let userData = {
            uid,
            phoneNumber,
            photoURL,
            emailVerified,
            displayName,
            email,
            isAnonymous,
          };
          const response = await fetchUser(uid);
          if (response.exists) {
            userData = {
              ...userData,
              ...response.data(),
            };
          }
          setUser(userData);
          const { apps } = userData.permissions;

          const [, userAppPermissions] =
            Object.entries(apps).find(([app, permissions]) => {
              return app === APP_NAME && permissions.active;
            }) || [];
          if (!userAppPermissions) {
            this.permissionError = 'FORBIDDEN';
            return;
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error(error);
        setUser(null);
      } finally {
        this.isUserInitialised = true;
      }
    });
  },
  computed: {
    ...userComputed,
    isMainApp() {
      return APP_NAME === APPS.rightsholder;
    },
  },
};
</script>

<style lang="scss" scoped></style>
