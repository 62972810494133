import Vue from 'vue';

const initialState = {
  user: null,
  isUserInitialised: false,
};

const state = Vue.observable(initialState);

export const setUser = (user) => (state.user = user);
export const getUser = () => state.user;
export const getUserStoreState = () => state;

export const userComputed = {
  user: {
    get() {
      return state.user;
    },
  },
  isUserInitialised: {
    get() {
      return state.isUserInitialised;
    },
    set(value) {
      state.isUserInitialised = value;
    },
  },
};

export const updateUser = (data) => {
  state.user = {
    ...state.user,
    ...data,
  };
};
