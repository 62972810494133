import { loadView } from './helpers';

const catalogRoutesChildren = (routeNamePrefix) => [
  // {
  //   path: '',
  //   name: `${routeNamePrefix}SelectCatalog`,
  //   component: loadView('apps/catalog/SelectCatalog'),
  //   beforeEnter: (to, from, next) => {
  //     if (to.name === 'DemoSelectCatalog') {
  //       return next(`/d/${to.params.algolia_index}/search/map`);
  //     }
  //     // return beforeEnterCatalogPages(routeNamePrefix)(to, from, next);
  //     return next();
  //   },
  // },
  {
    path: 'search',
    name: `${routeNamePrefix}SearchCatalogs`,
    component: loadView('apps/catalog/SearchCatalogs'),
    redirect: 'search/map',
    props: true,
    children: [
      {
        path: 'map',
        name: `${routeNamePrefix}MapView`,
        component: loadView('apps/catalog/views/MapView'),
        props: true,
      },
      {
        path: 'table',
        name: `${routeNamePrefix}TableView`,
        component: loadView('apps/catalog/views/TableView'),
        props: true,
      },
    ],
  },
];

export default [
  {
    path: '/',
    redirect: '/catalogs/search/map',
  },
  // {
  //   path: '/d/:algolia_index',
  //   component: {
  //     render: (h) => h('router-view'),
  //     created() {
  //       if (this.$route.name === 'SelectCatalog') {
  //         this.$router.push(
  //           `/d/${this.$route.params.algolia_index}/search/map`
  //         );
  //       }
  //     },
  //   },
  //   children: catalogRoutesChildren('Demo'),
  // },
  {
    path: '/catalogs',
    component: {
      render: (h) => h('router-view'),
    },
    children: catalogRoutesChildren(''),
  },
  {
    path: '/login',
    name: 'Login',
    component: loadView('Login'),
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: loadView('apps/catalog/views/subscribe/Subscribe'),
  },
];
