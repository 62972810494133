import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import rightsholdersRoutes from './rightsholdersRoutes';
import bulkRoutes from './bulkRoutes';
import playlistsRoutes from './playlistRoutes';
import worldRoutes from './worldRoutes';
import uploadRoutes from './uploadRoutes';
import catalogRoutes from './catalogRoutes';
import coveredRoutes from './coveredRoutes';

import { APPS, APP_NAME } from '@/constants/apps';

const routesConfig = {
  [APPS.rightsholder]: rightsholdersRoutes,
  [APPS.bulk]: bulkRoutes,
  [APPS.playlist]: playlistsRoutes,
  [APPS.world]: catalogRoutes,
  [APPS.upload]: uploadRoutes,
  [APPS.catalog]: catalogRoutes,
  [APPS.covered]: coveredRoutes,
};

let routes = Reflect.has(routesConfig, APP_NAME)
  ? routesConfig[APP_NAME]
  : routesConfig.rightsholders;

console.log('APP_NAME', APP_NAME);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    {
      path: '/forbidden',
      name: 'FORBIDDEN',
      component: {
        render(h) {
          return <div>You have no access</div>;
        },
      },
    },
  ],
});

export default router;
