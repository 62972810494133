export const exclude = (obj, keys) => {
  let newObj = {};
  for (let key in obj) {
    if (keys.includes(key)) {
      continue;
    }
    newObj[key] = obj[key];
  }

  return newObj;
};
