export const truncate = (str, len = 150) => {
  if (str && str.length > len) {
    const slicedStr = str.slice(0, len);

    const slicedStrWithoutEndingWhiteSpace = slicedStr.trimEnd();
    const padOffset =
      len + 3 - (slicedStr.length - slicedStrWithoutEndingWhiteSpace.length);

    return slicedStrWithoutEndingWhiteSpace.padEnd(padOffset, '.');
  }
  return str;
};
