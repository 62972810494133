export const APPS = {
  rightsholder: 'rightsholder',
  bulk: 'bulk',
  playlist: 'playlists',
  world: 'world',
  upload: 'upload',
  catalog: 'catalog',
  covered: 'covered',
};

const subdomain = window.location.host.split('.')[0];

export const APP_NAME =
  APPS[subdomain] || process.env.VUE_APP_NAME || APPS.rightsholder;

console.log({ APP_NAME });
